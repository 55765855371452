export const removeFileExtension = (fileName: string) => {
 
  const lastDotIndex = fileName.lastIndexOf('.');
  
  if (lastDotIndex === -1) {
    return fileName;
  }

  return fileName.slice(0, lastDotIndex);
}

export const renderFileTypeIcon = (contentType: string) => {
  const audioIconSrc = 'https://phmg-styles.s3.eu-west-2.amazonaws.com/icons/music-black-icon.svg'; 
  const documentIconSrc = 'https://phmg-styles.s3.eu-west-2.amazonaws.com/icons/document-black-icon.svg'; 

  if (contentType.startsWith('audio/')) {
    return <img src={audioIconSrc} alt="Audio File" width={20} height="auto"/>;
  }

  if (contentType.startsWith('text/')) {
    return <img src={documentIconSrc} alt="Text File" width={20} height="auto"/>;
  }

  return null; 
};
