import { ComponentType,useEffect, useState } from 'react';

interface Dimensions {
  width: number;
  height: number;
}

interface WithWindowDimensionsProps {
  windowWidth: number;
  windowHeight: number;
  isMobileSized: boolean;
  isTabletSized: boolean;
  isTabletSizedOrLess: boolean;
  isLargeTabletSizedOrLess: boolean;
  isLaptopSizedOrLess: boolean;
}

function withWindowDimensions<P>(
  WrappedComponent: ComponentType<P & WithWindowDimensionsProps>
) {
  return function WithWindowDimensions(props: P) {
    const [dimensions, setDimensions] = useState<Dimensions>({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    const updateWindowDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    useEffect(() => {
      updateWindowDimensions();
      window.addEventListener('resize', updateWindowDimensions);

      return () => {
        window.removeEventListener('resize', updateWindowDimensions);
      };
    }, []);

    return (
      <WrappedComponent
        {...props}
        windowWidth={dimensions.width}
        windowHeight={dimensions.height}
        isMobileSized={dimensions.width <= 530}
        isTabletSized={dimensions.width > 530 && dimensions.width <= 768}
        isTabletSizedOrLess={dimensions.width <= 768}
        isLargeTabletSizedOrLess={dimensions.width <= 1024}
        isLaptopSizedOrLess={dimensions.width <= 1100}
      />
    );
  };
}

export default withWindowDimensions;
