import * as S from './styles';

type FilterPillProps = {
  name: string;
  id: string;
  selected?: boolean;
  imgLink?: string;
  onClick?: () => void; 
  disabled: boolean;
};

const FilterPill = ({ name, selected = false, imgLink, onClick, disabled=false }: FilterPillProps) => {
  return (
    <S.Wrapper
      selected={selected}
      disabled={disabled}
      onClick={() => {
        if (onClick && !disabled) {
          onClick(); 
        }
      }}
    >
      <div className="filter-pill-inner">
        {imgLink && <img src={imgLink} />}
        <span>{name}</span>
      </div>
    </S.Wrapper>
  );
};

export default FilterPill;
