import { useEventMutation } from '@config/state/globalSlices/apiSlice/mutations';
import FilterPill from '@shared/components/FilterPill';
import { v4 as uuidv4 } from 'uuid';

import MobileFilters from '../MobileFilters';
import * as S from './styles';

type FiltersProps = {
  isMobileSized: boolean;
  isLoading: boolean;
  filters: {
    jobType: ('Auto Attendant' | 'On hold music' | 'Out of hours' | 'Voicemail')[];
    approved: boolean;
    sortBy?: 'uploaded_at' | 'job_type' | 'record_type' | 'file_name';
    sortOrder?: 'asc' | 'desc',
    isFiltered: boolean;
  };
  setFilters: React.Dispatch<React.SetStateAction<{
    jobType: ('Auto Attendant' | 'On hold music' | 'Out of hours' | 'Voicemail')[];
    approved: boolean;
    sortBy?: 'uploaded_at' | 'job_type' | 'record_type' | 'file_name';
    sortOrder?: 'asc' | 'desc'
    isFiltered: boolean;
  }>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  resetFilters: () => void;
};

type FilterOption = {
  name: string;
  id: 'Auto Attendant' | 'On hold music' | 'Out of hours' | 'Voicemail';
  selected: boolean;
};

type SortOption = {
  name: string;
  id: 'uploaded_at' | 'job_type' | 'record_type' | 'file_name';
  selected: boolean;
};

const Filters = (
  { 
    isMobileSized,
    filters, 
    setFilters, 
    isLoading, 
    setCurrentPage, 
    resetFilters, 
  }: FiltersProps) => {

  const [sendEvent] = useEventMutation();

  const filterOptions: FilterOption[] = [
    { name: 'Auto Attendant', id: 'Auto Attendant', selected: filters.jobType?.includes('Auto Attendant') },
    { name: 'On hold music', id: 'On hold music', selected: filters.jobType?.includes('On hold music') },
    { name: 'Out of hours', id: 'Out of hours', selected: filters.jobType?.includes('Out of hours') },
    { name: 'Voicemail', id: 'Voicemail', selected: filters.jobType?.includes('Voicemail') },
  ];
  
  const sortOptions: SortOption[] = [
    { name: 'Name', id: 'file_name', selected: filters.sortBy === 'file_name' },
    { name: 'Date', id: 'uploaded_at', selected: filters.sortBy === 'uploaded_at' },
    { name: 'Content', id: 'job_type', selected: filters.sortBy === 'job_type' },
    { name: 'Record Type', id: 'record_type', selected: filters.sortBy === 'record_type' },
  ];

  const handleFilterClick = (id: 'Auto Attendant' | 'On hold music' | 'Out of hours' | 'Voicemail') => {

    const eventPayload = {
      filter: id,
      page: 'My Studio'
    }

    sendEvent({ eventName: 'Used Filter', metadata: eventPayload });

    setFilters((prev) => ({
      ...prev,
      jobType: prev.jobType?.includes(id)
        ? prev.jobType.filter((item) => item !== id)
        : [...(prev.jobType || []), id], 
      isFiltered: true,
  
    }));
    setCurrentPage(0);
  };

  const handleSortClick = (id: 'uploaded_at' | 'job_type' | 'record_type' | 'file_name') => {
    setFilters((prev) => ({
      ...prev,
      sortBy: id,
      sortOrder: prev.sortBy === id && prev.sortOrder === 'asc' ? 'desc' : 'asc',
      isFiltered: true,
    }));
  };

  return (
    <>
      {!isMobileSized && (
        <S.FiltersWrapper>
          <S.FilterHeading>
            <img
              src="https://phmg-styles.s3.eu-west-2.amazonaws.com/icons/filter-icon-white.svg"
              alt=""
            />
            <span>Filter by:</span>
          </S.FilterHeading>

          <S.FiltersWrapperInner>
            {filterOptions.map((x) => (
              <FilterPill
                key={uuidv4()}
                name={x.name}
                id={x.id}
                selected={x.selected}
                disabled={isLoading}
                //@ts-ignore
                onClick={() => handleFilterClick(x.id)}
              />
            ))}

            <S.ResetFilters onClick={resetFilters} isDisabled={isLoading}>
              <img
                src="https://phmg-styles.s3.eu-west-2.amazonaws.com/icons/reset-icon-white.svg"
                alt=""
              />
              <span>Reset filters</span>
            </S.ResetFilters>
          </S.FiltersWrapperInner>
        </S.FiltersWrapper>
      )}

      {isMobileSized && (
        <MobileFilters
          filterOptions={filterOptions}
          handleFilterClick={handleFilterClick}
          sortOptions={sortOptions}
          isLoading={isLoading}
          handleSortClick={handleSortClick}
        />
      )}
    </>
  );
};

export default Filters;
