import styled from 'styled-components';

export const MobileFiltersWrapper = styled.div`

`

export const MobileFiltersWrapperHeader = styled.div`
  display: flex;
  gap: 10px;
`

export const MobileFiltersBody = styled.div`
  margin-top: 35px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

`
export const SortWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CustomSelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  select {
    background-color: black;
    color: white;
    border: 2px solid #FF609F;
    border-radius: 22px;
    padding: 13px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    appearance: none; 

    &:hover {
      background-color: white;
      color: black;
      border-color: white;
    }

    &:disabled {
      background-color: gray;
      border-color: lightgray;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  /* Custom dropdown arrow */
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    width: 8px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translateY(-50%) rotate(45deg);
    pointer-events: none;
  }

  &:hover::after {
    border-color: black;
  }
`;
