import api from '..';
import {
  AccountManagerParams,
  AccountManagerResponse,
  BrandDataResponse,
  BrandWorkshopParams,
  BrandWorkshopVisibleResponse,
  GetFilesByAccountIdParams,
  GetFilesByAccountIdResponse,
  InvoicesListParams,
  InvoicesListResponse,
  Location,
  LocationsResponse,
  PDFInvoiceParams,
  PDFInvoiceResponse,
  SiteProductionsFilesRequest,
  SiteProductionsFilesResponse,
  SiteProductionsSummary,
  SiteResponse,
  SitesResponse,
  SiteSummary,
  UpdateSiteDetailsParams } from '../types/locations';
import { Tags } from '../types/tags';

export const siteRequestsSlice = api.injectEndpoints({
  endpoints: (build) => ({
    getAllSites: build.query<Record<string, SiteSummary>, Record<string, never>>({
      query: () => ({
        url: '/sites',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: [Tags.Sites],
      transformResponse: (response) => {
        const { data: sites } = response as SitesResponse;

        const result: Record<string, SiteSummary> = {};

        for (const site of sites) {
          result[site.id] = site;
        }
        return result;
      },
    }),
    getSiteDetails: build.query<SiteSummary, string>({
      query: (id) => ({
        url: `/sites/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: (result) => [{ type: Tags.Sites, id: result?.id }],
      transformResponse: ({ data }: SiteResponse) => data,
    }),
    getSiteCountry: build.query<Location[], Record<string, never>>({
      query: () => ({
        url: '/sites/countries',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: [Tags.Countries],
      transformResponse: ({ data }: LocationsResponse) => data,
    }),
    getSiteCounties: build.query<Location[], Record<string, never>>({
      query: () => ({
        url: '/sites/counties',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: [Tags.Counties],
      transformResponse: ({ data }: LocationsResponse) => data,
    }),
    setSiteDetails: build.mutation<SiteResponse, UpdateSiteDetailsParams>({
      query: (body) => ({
        url: `/sites/${body.id}/account/${body.id}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body,
      }),
    }),
    getSiteProductions: build.query<SiteProductionsSummary, string>({
      query: (accountNumber) => ({
        url: `/sites/${accountNumber}/productions`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: [Tags.Productions],
    }),
    getSiteProductionsFiles: build.query<SiteProductionsFilesResponse, SiteProductionsFilesRequest>({
      query: (body) => ({
        url: `/sites/${body.accountNumber}/productions/${body.status}/${body.type}/files`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: (_, __, args) => [
        { type: Tags.ProductionFiles, accountNumber: args.accountNumber, filetype: args.type }
      ],
    }),
    getDynamicsAccountManager: build.query<AccountManagerResponse, AccountManagerParams>({
      query: (params) => ({
        url: `/sites/${params.siteId}/account/${params.accountNumber}/manager`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
    }),
    getInvoicesList: build.query<InvoicesListResponse, InvoicesListParams>({
      query: (params) => ({
        url: `/sites/${params.siteId}/invoices`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
    }),
    getPDFInvoice: build.query<PDFInvoiceResponse, PDFInvoiceParams>({
      query: (params) => ({
        url: `/sites/${params.siteId}/invoices/${params.invoiceId}/pdf?type=${encodeURIComponent(params.type)}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
    }),
    
    getBrandWorkshops: build.query<BrandDataResponse, BrandWorkshopParams>({
      query: (params) => {  
        return {
          url: `/sites/${params.siteId}/${params.accountNumber}/brand-workshops`,
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          // },
        };
      }
      
    }),

    getBrandWorkshopVisible: build.query<boolean, BrandWorkshopParams>({
      query: (params) => {  
        return {
          url: `/sites/${params.siteId}/${params.accountNumber}/brand-workshops/visible`,
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          // },
        };
      },
      transformResponse: (response: BrandWorkshopVisibleResponse ) => {
        return response.data;
      },
    }),

    getFilesByAccountId: build.query<GetFilesByAccountIdResponse, GetFilesByAccountIdParams>({
      query: (params) => {
        const { siteId, accountNumber, jobType, approved, sortBy, sortOrder, page, limit } = params;

        const queryParams = new URLSearchParams();
        if (jobType) {queryParams.append('jobType', jobType);}
        if (approved) {queryParams.append('approved', approved.toString());}
        if (sortBy) {queryParams.append('sortBy', sortBy);}
        if (page) {queryParams.append('page', page.toString());}
        if (limit) {queryParams.append('limit', limit.toString());}
        if (sortOrder) {queryParams.append('sortOrder', sortOrder);}
    
        return {
          url: `/sites/${siteId}/${accountNumber}/my-studio/files?${queryParams.toString()}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        };
      },
    }),
  }),
});
