import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
 position: relative;
 /* background-color: #151515; */
 height: 100%;

 @media (max-width: ${size.large}) {
  height: calc(100vh - 71px)

  }
`

export const SectionOneWrapper = styled.div`
  padding: 40px 20px 40px 20px;
`

export const SectionTwoWrapper = styled.div`
  padding: 10px 20px;
  max-width: 900px;
  width: 100%;
  margin: 0 auto; 
`;

export const NoFilesWrapper = styled.div`
    width: 100%;
  /* max-width: 500px; */
  margin: 0 auto;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: Arial, sans-serif;
  padding: 90px 20px;
`

export const NoFilesWrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  p{
    text-align: center;
    opacity: 0.5;
    font-size: 15px;
  }

  h4{
    text-align: center;
  }

`

export const TablesWrapper = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex-direction: column;
`;

export const TableText = styled.span`
  font-weight: 16px !important;
`

export const TableFileName = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

export const PaginationWrapper = styled.div`

  text-align: center;
  width: 100%;

.pagination {
    margin: 25px auto;
    display: inline-flex;
    /* justify-content: flex-end;  */
    justify-content: center;
   
    list-style: none;
    outline: none;
    border: 1px solid #d5d9d9;
    white-space: nowrap;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
    /* background-color: #fff; */
    color: #6f7373;

   
}




  /* .paginated > li {
    height: 100%;
  } */

  .pagination > li > a {
    padding: 5px 20px;
    height: 100%;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    color: #0f1111;
    line-height: 46px;


    @media (max-width: ${size.small}) {
      padding: 10px 16px;

  }
  }

  .pagination > .active {
    border: 1px solid black;
  }

  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    /* background-color: #febd69; */
    /* border: 1px solid black; */
    outline: none;
    color: #FF609E;
   
  }
  .pagination > li > a,
  .pagination > li > span {
    color: #fff;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span,
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-radius: unset;
  }

  .disabled a {
    color: #6f7373 !important;
    cursor: default !important;
  }
`;
