import styled from 'styled-components';

const Table = ({ className, children }: any) => <TableStyles className={className}>{children}</TableStyles>;

Table.Head = function TableHead({ children }: any) {
  return (
    <TableHeadStyles>
      <TableHeadRowStyles>{children}</TableHeadRowStyles>
    </TableHeadStyles>
  );
};

Table.Heading = function TableHeading({
  children,
  onSort = () => {},
  sortKey,
  currentSort = { key: '', direction: 'asc' },
}: any) {
  const isActive = currentSort.key === sortKey;
  
  const downIcon = (
    <img
      src="https://phmg-styles.s3.eu-west-2.amazonaws.com/icons/sorted-down-icon.svg"
      alt="Sorted Descending"
    />
  );
  const upIcon = (
    <img
      src="https://phmg-styles.s3.eu-west-2.amazonaws.com/icons/sorted-up-icon.svg"
      alt="Sorted Ascending"
    />
  );
  const unsortedIcon = (
    <img
      src="https://phmg-styles.s3.eu-west-2.amazonaws.com/icons/unsorted-icon.svg"
      alt="Unsorted"
    />
  );

  return (
    <TableHeadingStyles onClick={() => sortKey && onSort(sortKey)} isActive={isActive}>
    
      <TableHeadingContent>
  
        {isActive && (
          <SortIndicator>
            {currentSort.direction === 'asc' ? upIcon : downIcon}
          </SortIndicator>
        )}
        {!isActive && sortKey && unsortedIcon}
        {children}
      </TableHeadingContent>

    </TableHeadingStyles>
  );
};

Table.Body = function TableBody({ children }: any) {
  return <tbody>{children}</tbody>;
};

Table.Row = function TableRow({ children }: any) {
  return <TableRowStyles>{children}</TableRowStyles>;
};

Table.Cell = function TableCell({ children }: any) {
  return <TableCellStyles>{children}</TableCellStyles>;
};

export default Table;

// Styled Components
const TableStyles = styled.table`
  width: 100%;
  grid-column: 1 / -1;
  border-radius: 10px;
  border-spacing: 0;
  overflow: hidden;
`;

const TableHeadStyles = styled.thead`
  background-color: var(--black);
  height: 40px;

  @media (min-width: 768px) {
    height: 50px;
  }
`;

const TableHeadRowStyles = styled.tr`
  height: 40px;

  @media (min-width: 768px) {
    height: 50px;
  }
`;

const TableHeadingStyles = styled.th<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? '#FF609E' : 'var(--white)')};
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.5px;
  text-align: left;
  padding: 10px 30px;
  cursor: pointer;

  &:hover {
    color: #FF609E;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    letter-spacing: 1px;
  }
`;

const TableHeadingContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

`

const SortIndicator = styled.p`
  /* margin-left: 10px; */
  font-size: 12px;
  color: #fff !important;
`;

const TableCellStyles = styled.td`
  padding: 10px 30px;
  border-bottom: 1px solid var(--light-grey);
  font-weight: 300;
  max-width: 200px;
  overflow-wrap: break-word;
`;

const TableRowStyles = styled.tr`
  background-color: var(--white);
  height: 50px;
  padding: 10px 30px;

  &:nth-of-type(odd) {
    background-color: #fbfbfb;
  }

  &:last-of-type {
    ${TableCellStyles} {
      border-bottom: none;
    }
  }

  @media (min-width: 768px) {
    height: 60px;
  }
`;
