import styled from 'styled-components';

type WrapperProps = {
  selected: boolean;
  disabled: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${(props) => (props.selected ? 'white' : 'black')};
  border: 2px solid ${(props) => (props.selected ? 'white' : '#FF609F')};
  color: ${(props) => (props.selected ? 'black' : '#fff')};
  border-radius: 22px;
  text-align: center;
  
  padding: 13px 20px;
  font-size: 16px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  width: auto;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


  .filter-pill-inner {
    display: flex;
    gap: 10px;
    align-items: center;

    img {
      width: 17px;
      height: auto;
      filter: ${(props) => (props.selected ? 'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(0%)' : 'invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(0%)')};
    }
  }


  &:hover {
    background-color: white;
    border-color: white;
    color: black;
  }

  > img {
    width: 17px;
    height: auto;

  }

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none; 
  `}

`;
