import FilterPill from '@shared/components/FilterPill';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import * as S from './styles';

type MobileFiltersProps = {
  filterOptions: {
    name: string;
    id: 'Auto Attendant' | 'On hold music' | 'Out of hours' | 'Voicemail',
    selected: boolean;
  }[];
  handleFilterClick: (id: 'Auto Attendant' | 'On hold music' | 'Out of hours' | 'Voicemail') => void;
  sortOptions: {
    name: string;
    id: 'uploaded_at' | 'job_type' | 'record_type' | 'file_name',
    selected: boolean;
  }[];
  handleSortClick: (id: 'uploaded_at' | 'job_type' | 'record_type' | 'file_name') => void;
  isLoading: boolean;
};

const MobileFilters = ({ 
  filterOptions, 
  handleFilterClick, 
  sortOptions, 
  handleSortClick, 
  isLoading 
}: MobileFiltersProps) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const toggleFilters = () => {
    setIsFiltersOpen((prev) => {
      const newState = !prev;
      return newState;
    });
  };

  return (
    <S.MobileFiltersWrapper>
      <S.MobileFiltersWrapperHeader>
        <FilterPill
          imgLink="https://phmg-styles.s3.eu-west-2.amazonaws.com/icons/filter-icon-white.svg"
          name="Filters"
          id={''}
          selected={isFiltersOpen}
          disabled={isLoading}
          onClick={toggleFilters}
        />
       
        <S.SortWrapper>
          <S.CustomSelectWrapper>
            <select
              id="sortSelect"
              onChange={(e) => handleSortClick(e.target.value as 'uploaded_at' | 'job_type' | 'record_type' | 'file_name')}
              disabled={isLoading}
              value={sortOptions.find((option) => option.selected)?.id || ''}
            >
              {sortOptions.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
            </select>
          </S.CustomSelectWrapper>
        </S.SortWrapper>

      </S.MobileFiltersWrapperHeader>

      {isFiltersOpen && (
        <S.MobileFiltersBody>
          {filterOptions?.map((x) => (
            <FilterPill
              key={uuidv4()}
              name={x.name}
              id={x.id}
              disabled={isLoading}
              selected={x.selected}
              onClick={() => handleFilterClick(x.id)}
            />
          ))}
        </S.MobileFiltersBody>
      )}
  
    </S.MobileFiltersWrapper>
  );
};

export default MobileFilters;
