import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  /* max-width: 500px; */
  margin: 0 auto;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: Arial, sans-serif;
  margin-left: 20px;
  margin-right: 20px;
`;

export const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const RowDate = styled.div`
  font-size: 12px;
  color: #888;
`;

export const RowInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #000;
`;

export const RowSubtitleWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 8px;

  > img{
    width: 12px;
  }
`

export const RowSubtitle = styled.div`
  font-size: 14px;
  color: #666;
  
`;

export const RowIcon = styled.div`
  display: flex;
  align-items: center;
`;

// export const DownloadButton = styled.button`
//   background-color: #ff5da2;
//   border: none;
//   color: white;
//   font-size: 18px;
//   padding: 6px 10px;
//   border-radius: 50%;
//   cursor: pointer;
//   transition: transform 0.2s ease-in-out;

//   &:hover {
//     transform: scale(1.1);
//   }

//   &:active {
//     transform: scale(0.95);
//   }
// `;

export const SortButton = styled.button`
  background: #ff5da2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  margin: 4px 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff76b2;
  }
`;
