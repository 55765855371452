import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const HeroWrapper = styled.div`
    text-align: center;
    color: #fff;
    /* background-color: blue; */
    display: flex;
    justify-content: center;  
    align-items: center;
    flex-direction: column;
    /* margin: 0px 20px 40px 20px; */
    border-radius: 16px;
    padding: 40px;

    .my-studio-headphones{
      width: 64px;
      height: auto;
      margin-bottom: 20px;
    }
    
    .my-studio-title{
      font-size: 38px;
      display: inline;
    }


    .description {
      margin-top: 10px;
      font-size: 15px;
      max-width: 510px;
      opacity: 0.5;
      letter-spacing: 0;
      line-height: 1.5;
   
    }


    @media (max-width: ${size.medium}) {

      padding: 40px 20px 40px 20px;
  
      .my-studio-title {
        font-size: 25px;
      }

      .company {
        font-size: 25px;
        line-height: 0.5;
     }
    }

    @media (max-width: ${size.small}) {
      margin: 0px 10px 0px 10px;

     .my-studio-title{
        font-size: 24px;
      }

      .company {
        font-size: 24px;
        line-height: 0.3;
     }
    }

`;
