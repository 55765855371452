import * as S from './styles';

const Hero = () => {

  return (
    <S.HeroWrapper>

      <img src="https://phmg-styles.s3.eu-west-2.amazonaws.com/icons/my-studio-headphones-icon.png" alt="" className='my-studio-headphones'/>

      <h2 className='my-studio-title'>
        My Studio
      </h2>

      <p className='description'>
      All the elements of your audio production are ready at your fingertips—every detail at your command.
       Full access. Total control. This is your Brand’s Sound.
      </p>
     
    </S.HeroWrapper>
  )
}

export default Hero;
