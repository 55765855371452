import api from '..';
import { EventParams,FormResponse, SubmitAccountManagerFormParams, SubmitBrandWorkshopFeedbackParams } from '../types/locations';

export const siteMutationsSlice = api.injectEndpoints({
  endpoints: (build) => ({
    submitAccountManagerForm: build.mutation<FormResponse, SubmitAccountManagerFormParams>({
      query: ({ message, subject, siteName, siteId, accountNumber }) => ({
        url: `/sites/${siteId}/account/${accountNumber}/manager`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        method: 'POST',
        body: {
          message,
          subject,
          siteName,
        },
      }),
    }),
    submitBrandWorkshopFeedback: build.mutation<FormResponse, SubmitBrandWorkshopFeedbackParams>({
      query: ({ message, siteName, siteId, accountNumber, brandName }) => ({
        url: `/sites/${siteId}/brand-workshop/${accountNumber}/feedback`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        method: 'POST',
        body: {
          message,
          siteName,
          brandName
        },
      }),
    }),
    event: build.mutation<object, EventParams>({
      query: (body) => ({
        url: '/sites/event',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        method: 'POST',
        body,
      }),
    }),
  }),
});
