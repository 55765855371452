import styled from 'styled-components';

export const FilterHeading = styled.div`
  color: #fff;
  display: inline-block;
  font-size: 16px;
  display: flex;
  gap: 9px;
`
export const FiltersWrapper = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
`;

export const FiltersWrapperInner = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
`;

type ResetFiltersProps = {
  isDisabled: boolean;
};

export const ResetFilters = styled.div<ResetFiltersProps>`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 6px;
  text-decoration: underline;
  cursor: pointer;
  color: #fff;
  opacity:  ${(props) => (props.isDisabled ? '0.5' : '1')};

  > img {
    width: 15px;
    height: auto;
  }
`;
