import AudioPlayer from '@shared/components/AudioPlayer';
import { v4 as uuidv4 } from 'uuid';

import RationaleComponent from '../RationaleText';
import * as S from './styles';

type Props = {
  icon: string;
  title: string;
  subtitle?: string;
  tracks: Array<{ name: string, url: string, rationale?: string | null }>
};

const Audio = ({ icon, title, subtitle, tracks }: Props) => {

  return (
    <S.AudioWrapper>
      <img src={icon} className="audio-icon" alt={title} />
      <h2>{title}</h2>

      {subtitle && <p>{subtitle}</p>}

      <S.Player>
        {tracks?.map((track) => {

          return (
            <S.TrackWrapper key={uuidv4()}>
              <h3>{track.name}</h3>
              <div className='player-inner'>
                <AudioPlayer
                  id={uuidv4()}
                  name={track.name}
                  url={track.url}
                />
              </div>

              {
                track?.rationale && (
                  <RationaleComponent rationale={track.rationale} />
                )
              }

            </S.TrackWrapper>

          )

        })}

      </S.Player>
   
    </S.AudioWrapper>
  )
}

export default Audio;
