import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  border: none;
  background: none;

  img{
    cursor: pointer;
    max-width: 32px;
    height: auto;
  }
`;
