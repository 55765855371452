import { useEventMutation } from '@config/state/globalSlices/apiSlice/mutations';
import { useGetFilesByAccountIdQuery } from '@config/state/globalSlices/apiSlice/requests';
import { Button } from '@phmg/storybook/v1/component';
import SidebarPageLayout from '@shared/components/SidebarPageLayout';
import Table from '@shared/components/Table';
import withWindowDimensions from '@shared/utils/windowDimensions';
import React from 'react'
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import DownloadButton from './components/DownloadButton';
import Filters from './components/Filters';
import Hero from './components/Hero';
import MobileTable from './components/MobileTable';
import * as S from './styles';
import { removeFileExtension, renderFileTypeIcon } from './utils';

type MyStudioPageProps = {
  isLaptopSizedOrLess: boolean;
  isLargeTabletSizedOrLess: boolean;
  isMobileSized: boolean;
  isTabletSized: boolean;
  isTabletSizedOrLess: boolean;
  windowHeight: number;
  windowWidth: number;
};

const MyStudioPage = ({ isMobileSized, windowWidth }: MyStudioPageProps) => {
  const { siteId, siteAccNumber } = useParams();
  const navigate = useNavigate();
  const [sendEvent] = useEventMutation();

  const [currentPage, setCurrentPage] = useState(0);

  // Separate state for sorting
  const [sorting, setSorting] = useState<{
    sortBy: 'uploaded_at' | 'job_type' | 'record_type' | 'file_name';
    sortOrder: 'asc' | 'desc';
  }>({
    sortBy: 'uploaded_at',
    sortOrder: 'asc',
  });

  const [filters, setFilters] = useState<{
    isFiltered: boolean;
    jobType: ('Auto Attendant' | 'On hold music' | 'Out of hours' | 'Voicemail')[];
    approved: boolean;
      }>({
        isFiltered: false,
        jobType: [],
        approved: true,
      });

  const {
    data: queryData,
    isLoading: isFilesLoading,
    refetch,
  } = useGetFilesByAccountIdQuery(
    {
      siteId: siteId as string,
      accountNumber: siteAccNumber as string,
      jobType: filters.jobType.join(','),
      approved: filters.approved,
      sortBy: sorting.sortBy,
      sortOrder: sorting.sortOrder,
      page: currentPage + 1,
      limit: 8,
    },
    { skip: !siteId || !siteAccNumber }
  );

  const files = queryData?.data || [];

  const isLoading = isFilesLoading;

  const pagination = queryData?.pagination || { page: 1, totalPages: 1 };
  const totalPages = pagination.totalPages;

  useEffect(() => {
    if (siteId && siteAccNumber) {
      refetch();
    }
  }, [filters, sorting, refetch, siteId, siteAccNumber]);

  useEffect(() => {

    const key = 'visitedPages';
    const pageName = 'My Studio';

    const visitedPages = JSON.parse(sessionStorage.getItem(key) || '[]');

    if (!visitedPages.includes(pageName)) {
     
      sendEvent({ eventName: 'Page Visited', metadata: { page: pageName } });

      visitedPages.push(pageName);
      sessionStorage.setItem(key, JSON.stringify(visitedPages));
    }
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteAccNumber]);

  const handleSort = (key: 'uploaded_at' | 'job_type' | 'record_type' | 'file_name') => {
    setSorting((prevSorting) => ({
      sortBy: key,
      sortOrder: prevSorting.sortBy === key && prevSorting.sortOrder === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  const resetFilters = () => {
    setFilters((prev) => ({
      ...prev,
      jobType: [],
      isFiltered: false,
    }));
  };

  const hasFiles = files.length > 0;

  const getInTouchHandler = () => {
    navigate(`/locations/${siteId}/account/manager`);
  }

  return (
    <SidebarPageLayout pageTitle="PHMG | My Studio" isLoading={isLoading} bgColor="#151515" >
      <S.Wrapper>
        <S.SectionOneWrapper>
          <Hero />
          {
            (files.length > 1 || filters.isFiltered) && (
              <Filters 
                isMobileSized={isMobileSized} 
                setFilters={setFilters} 
                filters={filters} 
                isLoading={isLoading}
                setCurrentPage={setCurrentPage}
                resetFilters={resetFilters}
              />
            )
          }

        </S.SectionOneWrapper>

        {
          !hasFiles && !filters.isFiltered && (
            <S.SectionTwoWrapper>

              <S.NoFilesWrapper>

                <S.NoFilesWrapperInner>
                  <h4>You have no productions available.</h4>
                  <p>Nothing seems to be here. If you were expecting any productions, Speak to your account manager.</p>
                  <Button variant="default" label="Get in touch" onClick={getInTouchHandler}/>

                </S.NoFilesWrapperInner>

              </S.NoFilesWrapper>

            </S.SectionTwoWrapper>
          )
        }

        {
          !hasFiles && filters.isFiltered && (
            <S.SectionTwoWrapper>

              <S.NoFilesWrapper>

                <S.NoFilesWrapperInner>
                  <h4>No productions match your filter</h4>
                  <p>Try adjusting your filters or clear all filters to view all available productions.</p>
                  <Button variant="default" label="Reset filters" onClick={resetFilters}/>

                </S.NoFilesWrapperInner>

              </S.NoFilesWrapper>

            </S.SectionTwoWrapper>
          )
        }

        {files.length > 0 && (
          <S.SectionTwoWrapper>

            <S.TablesWrapper>
              {windowWidth < 685 ? (
                <MobileTable files={files} />
              ) : (
                <Table>
                  <Table.Head>
                    <Table.Heading />
                    <Table.Heading 
                      onSort={handleSort} 
                      sortKey="file_name" 
                      currentSort={{ key: sorting.sortBy, direction: sorting.sortOrder }}>
           Name
                    </Table.Heading>
                    <Table.Heading 
                      onSort={handleSort} 
                      sortKey="job_type" 
                      currentSort={{ key: sorting.sortBy, direction: sorting.sortOrder }}>
         Content
                    </Table.Heading>
                    <Table.Heading 
                      onSort={handleSort} 
                      sortKey="uploaded_at" 
                      currentSort={{ key: sorting.sortBy, direction: sorting.sortOrder }}>
           Date
                    </Table.Heading>
                    <Table.Heading />
                  </Table.Head>
                  <Table.Body>
         
                    {files.length > 0 && files?.map((file) => (
                      <Table.Row key={file.file_id}>
                        <Table.Cell>

                          { renderFileTypeIcon(file.content_type) }
             
                        </Table.Cell>
                        <Table.Cell>
                          <S.TableFileName>{removeFileExtension(file.file_name)}</S.TableFileName>
                        </Table.Cell>
                        <Table.Cell>
                          <S.TableText>{file.job_type}</S.TableText>
                        </Table.Cell>
                        <Table.Cell>
                          <S.TableText>{new Date(file.uploaded_at).toLocaleDateString()}</S.TableText>
                        </Table.Cell>
                        <Table.Cell>
                          <DownloadButton link={file.s3_url} ariaLabel="Download File" jobType={file.job_type} />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}

            </S.TablesWrapper>

            {totalPages > 1 && (
              <S.PaginationWrapper>
                <ReactPaginate
                  previousLabel={'Prev'}
                  nextLabel={'Next'}
                  breakLabel={'...'}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  initialPage={0}
                  onPageChange={handlePageChange}
  
                  breakClassName={'break-me'}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  disabledClassName={'disabled'}
                />
              </S.PaginationWrapper>
            )}
          </S.SectionTwoWrapper>
        )}
     
      </S.Wrapper>
    </SidebarPageLayout>
  );

};

export default withWindowDimensions(MyStudioPage);
